import { registerIconLibrary } from '@shoelace-style/shoelace';

/**
 * Import UI core styles
 * @see https://code.roche.com/cortex/cortex-ui-core
 */
import '@cortex/ui-core/fonts.css';
import '@cortex/ui-core/theme.css';

/**
 * Import UI Vue styles
 * @see https://code.roche.com/cortex/cortex-ui-vue
 */
import '@cortex/ui-vue/style.css';

/**
 * Register Shoelace icon library
 * @see https://shoelace.style/components/icon#icon-libraries
 */
registerIconLibrary('default', {
  resolver: (name) => `/icons/cortex/${name}.svg`,
});
